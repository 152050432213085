
<template>
  <div>
    <v-card flat style='border-radius: 18px 18px 18px 18px;'>
      <v-card-title class='primary white--text py-3 px-5'>
        <span v-if="$route.name =='NewMember'">Cadastrar novo membro</span>
        <span v-if="$route.name =='EditMember'">Informações</span>
        <v-spacer></v-spacer>
        <v-btn text color="white" v-if="$route.name =='EditMember'" @click="enable_fields = !enable_fields" rounded><v-icon left>mdi-pencil</v-icon> Habilitar edição</v-btn>
        <v-btn text class="ml-2" icon color="white" @click="$router.push({name: 'Members'})" rounded><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      
      <v-card-text class="pt-3">
        <v-form ref="newUserForm" :readonly="!enable_fields">
          <v-text-field
            v-model="user.name"
            prepend-icon="mdi-account-outline"
            label="Nome"
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            v-model="user.email"
            prepend-icon="mdi-at"
            label="E-mail"
            :rules="emailRules"
            :disabled="!!$route.params.id"
          ></v-text-field>
          <v-text-field
            v-model="user.phone"
            prepend-icon="mdi-phone"
            label="Telefone"
            :rules="required"
          ></v-text-field>
          <v-select
            prepend-icon="mdi-account-tie-outline"
            :items="roles"
            v-model="user.role_ids"
            label="Cargos"
            :rules="required"
          ></v-select>
          <v-select
            v-if="is_tech_secretary"
            prepend-icon="mdi-account-tie-outline"
            :items="countries"
            item-text="text"
            item-value="value"
            v-model="user.country"
            label="Secretaria"
            :rules="required"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center pb-5">
        <v-btn v-if="$route.name =='NewMember'" color="success" @click="submitMember" >
          <v-icon left>mdi-content-save</v-icon> Salvar 
        </v-btn>
        <v-btn v-if="$route.name =='EditMember'" :disabled="!enable_fields" color="success" @click="submitMember" >
          <v-icon left>mdi-content-save</v-icon> Atualizar 
        </v-btn>
        <v-btn v-if="$route.name =='EditMember'" :disabled="!enable_fields" color="error" @click="$router.push({name: 'Members'})">
          <v-icon  left>mdi-cancel</v-icon> Cancelar 
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-loading v-if="saving" :loading="saving" loadingText="Salvando"></dialog-loading>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';
import Api from '@/api/index'

export default {
  name: 'NewMember',
  mixins: [
    validations
  ],
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        role_ids: '',
        country: '',
      },
      roles: [],
      saving: false,
      id: null,
      enable_fields: true,
      loading: true,
      countries:[
        // {value: 'brazilian', text: 'Brasil'},
        {value: 'argentine', text: 'Argentina'},
        {value: 'colombian', text: 'Colombia'},
        {value: 'uruguayan', text: 'Uruguai'},
        {value: 'paraguayan', text: 'Paraguai'},
        {value: 'other', text: 'Outro'}
      ]
    }
  },
  mounted() {
    if(this.$route.name == 'EditMember'){
      this.enable_fields = false
    }
    this.setRoles()
    if(this.$route.params.id){
      this.setSelectedMember(this.$route.params.id)
    }
  },
  methods: {
    setSelectedMember(id){
      Api.User.show(id).then(r => r.data).then(d => {
        console.log(d)
        this.user = {
          name: d.name,
          email: d.email,
          phone: d.phone,
          role_ids: d.role.id,
          country: d.country,
        }
      }).catch((err)=>{
        this.addNotification({type: 'error', msg: `${err}`})
        if(err.response && err.response.status && err.response.status == 404){
          this.$router.push({name: 'Members', params: {any_member_updated: false}})
        }
      });
    },
    setRoles(){
      this.loading = true;
      Api.Role.index().then(r=>r.data).then(d => {
        this.roles = d.map((c)=>(
          {
            text: c.translated,
            value: c.id
          }
        ))
        this.loading = false;
      }).catch((err) => this.addNotification({type: 'error', msg: `${err}`}));
    },
    submitMember(){
      if (this.$refs.newUserForm.validate()) {
        const ACTION = {
          METHOD: this.$route.params.id? 'update' : 'create',
          TEXT: this.$route.params.id? 'atualizar' : 'criar novo',
        }
        
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja ${ACTION.TEXT} membro?`,
          icon: 'question',
        }).then(r=>{
          if (r.isConfirmed) {
            this.saveMember(ACTION)
          };
        });
      };
    },
    saveMember(ACTION){
      this.saving = true;
      let user = this.prepareUserInfos()

      setTimeout(() => {
        Api.User[ACTION.METHOD](user).then((r) => {
          this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
          this.$router.push({name: 'Members', params: {any_member_updated: true}})
        }).catch((err) =>{
          this.addNotification({type: 'error', msg: `Erro: ${err}`})
        }).finally(() => {
          this.saving = false;
        })
      }, 1000);
    },
    prepareUserInfos(){
      let user = {
        id: this.$route.params.id ? this.$route.params.id : null,
        name: this.user.name,
        phone: this.user.phone,
        role_ids: [this.user.role_ids],
        country: this.is_tech_secretary ? this.user.country : 'brazilian',
        email: this.user.email
      }

      return user
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    is_tech_secretary(){
      return this.roles?.find(el => el.value == this.user?.role_ids)?.text == 'Secretaria técnica' 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
}
</script>

<style scoped>

</style>